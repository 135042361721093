<template>
  <router-view/>
</template>

<script setup>
import { useHead } from "@vueuse/head";
import { computed } from "vue";
import { useRoute } from "vue-router";

const currentRoute = useRoute();

useHead({
  title: computed(() => (currentRoute.meta.title ?? '')),
  meta: computed(() => (currentRoute.meta.meta ?? [])),
  script: computed(() => (currentRoute.meta.script ?? [])),
});
</script>

<style>
@import "@/assets/css/normalize.css";

/* fonts */
@font-face {
  font-family: 'Raleway';
  src: local('Raleway Medium'), local('Raleway-Medium'),
  url('@/assets/fonts/raleway/Raleway-Medium.woff2') format('woff2'),
  url('@/assets/fonts/raleway/Raleway-Medium.woff') format('woff');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
  url('@/assets/fonts/raleway/Raleway-SemiBold.woff2') format('woff2'),
  url('@/assets/fonts/raleway/Raleway-SemiBold.woff') format('woff');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway Bold'), local('Raleway-Bold'),
  url('@/assets/fonts/raleway/Raleway-Bold.woff2') format('woff2'),
  url('@/assets/fonts/raleway/Raleway-Bold.woff') format('woff');
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}

/* reset styles */
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

nav,
footer,
header,
aside {
  display: block;
}

input,
button,
textarea {
  font-family: inherit;
}

textarea {
  resize: none;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  max-width: 100%;
  max-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
  margin: 0;
}

picture {
  display: block;
}

/* glob */
body {
  width: 100%;
  line-height: normal;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  color: #353535;
  background-color: #F2F1F7;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
}

.container {
  padding: 0 12px;
  max-width: 504px;
  margin: 0 auto auto;
  width: 100%;
}

input::-moz-placeholder {
  color: rgba(53, 53, 53, .5);
}

input::-webkit-input-placeholder {
  color: rgba(53, 53, 53, .5);
}

textarea::-moz-placeholder {
  color: rgba(53, 53, 53, .5);
}

textarea::-webkit-input-placeholder {
  color: rgba(53, 53, 53, .5);
}


input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
  font-size: 20px;
  font-weight: 600;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}

input[type='number'] {
  width: 100%;
  height: 48px;
  padding: 0 67px 0 20px;
  border-radius: 8px;
  border: 1px solid rgba(5, 187, 149, .3);
  background: #fff;
}


input[type='range'] {
  width: 100%;
  border-radius: 4px;
  -webkit-appearance: none;
  background-color: #E7E5F0;
}

input[type='range']::-webkit-slider-runnable-track {
  height: 8px;
  -webkit-appearance: none;
  border-radius: 4px;
  color: #E7E5F0;
}

input[type='range']::-webkit-slider-thumb {
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: 6px;
  background: radial-gradient(111.8% 111.8% at 0% 0%, #08D9AE 0%, #05BB95 100%), #FFF;
  margin-top: -9px;
  /* box-shadow: 10000px 0 0 100000px #43e5f7; */
}

input[type="range"]::-moz-range-progress {
  background-color: #C8C5D8;
}

input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

input[type="range"]::-ms-fill-lower {
  background-color: #43e5f7;
}

input[type="range"]::-ms-fill-upper {
  background-color: #9a905d;
}

input[type=range]::-webkit-progress-value {
  background-color: #C8C5D8;
}


.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.radio__wrapper {
  display: flex;
  padding: 16px 12px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: pointer;
  transition: border-color .2s ease;
}

.radio__input:checked + .radio__wrapper {
  border-color: rgba(5, 187, 149, 0.50);
}

.radio__checked {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-right: 12px;
  border-radius: 50%;
  border: 2px solid #E0E0E0;
  transition: border .2s ease;
}

.radio__input:checked + .radio__wrapper .radio__checked {
  border: 10px solid #05BB95;
}

.radio__input:checked + .radio__wrapper .quiz__field-text {
  color: #05BB95;
}

.checkbox__text_size {
  font-size: 14px;
  font-weight: 500;
}

/* quiz */
.quiz__list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.quiz__option {
  margin-bottom: 12px;
}

.quiz__option:last-child {
  margin-bottom: 0;
}


.quiz__field {
  display: block;
  margin-bottom: 20px;
}

.quiz__field:last-child {
  margin-bottom: 0;
}

.quiz__field-text {
  padding-top: 2px;
  font-size: 20px;
  font-weight: 600;
  transition: color .2s ease;
}

.related-field {
  opacity: .3;
  pointer-events: none;
  transition: opacity .2s ease;
}

.related-field.active {
  opacity: 1;
  pointer-events: all;
}

.related-field__title {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.field-input {
  width: 100%;
  height: 64px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  color: #353535;
}

.input-textarea {
  height: 80px;
}


.checkbox-field {
  display: flex;
  padding: 16px 12px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: pointer;
  transition: border-color .2s ease;
}

.checkbox-field__wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-field:checked + .checkbox-field__wrapper {
  border-color: rgba(5, 187, 149, 0.50);
}

.checkbox-field__checked {
  position: relative;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  margin-right: 12px;
  border-radius: 5px;
  border: 2px solid #E0E0E0;
  transition: border-color .2s ease, background-color .2s ease;
}

.checkbox-field__checked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='1' width='26' height='26' rx='5' fill='%2305BB95'/%3E%3Cpath d='M22 9L13.6296 18.2848C12.8139 19.1896 11.3856 19.1612 10.6065 18.2246L7 13.8889' stroke='white' stroke-width='2'/%3E%3Crect x='1' y='1' width='26' height='26' rx='5' stroke='%2305BB95' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center;
  opacity: 0;
  transition: opacity .2s ease;
}

.checkbox-field__input:checked + .checkbox-field__wrapper .checkbox-field__checked {
  background-color: #05BB95;
  border: 2px solid #05BB95;
}

.checkbox-field__input:checked + .checkbox-field__wrapper .checkbox-field__checked::before {
  opacity: 1;
}

.checkbox-field__text {
  font-size: 20px;
  font-weight: 600;
  color: rgba(53, 53, 53);
}

.checkbox-field__input:checked + .checkbox-field__wrapper .checkbox-field__text {
  color: #05BB95;
}

@media (min-width: 1200px) {
  .radio__wrapper:hover .quiz__field-text {
    color: #05BB95;
  }

  .radio__wrapper:hover .radio__checked {
    border-color: #05BB95;
  }
}
</style>
