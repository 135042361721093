import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    name: 'main',
    path: '/',
    component: async () => import('@/views/MainView.vue'),
    meta: {
      name: 'Главная',
      title: 'Фулфилмент для продавцов на маркетплейсах',
      meta: [
        { name: 'description', content: 'Фулфилмент для маркетплейсов Вайлдберриз, Озон, Яндекс, СберМегаМаркет. Упаковка и доставка вашего товара напрямую на склады маркетплейсов из Краснодара, Крыма и Казани', },
        { property: 'og:title', content: 'Фулфилмент для продавцов на маркетплейсах', },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://xn--93-9kc1abjhxv.xn--p1ai/" },
        { property: "og:image", content: "https://rcsmpartner.ru/img/logo.d82dcf85.svg" },
        { property: "og:description", content: "Фулфилмент для маркетплейсов Вайлдберриз, Озон, Яндекс, СберМегаМаркет. Упаковка и доставка вашего товара напрямую на склады маркетплейсов из Краснодара, Крыма и Казани" },
        { property: "og:site_name", content: "колибри93.рф" },
        { property: "og:locale", content: "ru_RU" },
      ],
      script: [
        {
          type: 'application/ld+json',
          tagPosition: "head",
          textContent: () => {
            return JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "WebSite",
              "name": "колибри93.рф",
              "url": "https://xn--93-9kc1abjhxv.xn--p1ai/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://xn--93-9kc1abjhxv.xn--p1ai/?q={query}{search_term_string}",
                "query-input": "required name=search_term_string"
              }
            })
          }
        },
        {
          type: 'application/ld+json',
          tagPosition: "head",
          textContent: () => {
            return JSON.stringify({
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Упаковка и доставка вашего товара напрямую на склады маркетплейсов",
              "url": " https://xn--93-9kc1abjhxv.xn--p1ai/",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": " Краснодар, Круговая 52",
                "addressLocality": "г. Краснодар ",
                "addressCountry": "Россия"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+7(900)-300-40-30"
              }
            })
          }
        },
        {
          type: 'application/ld+json',
          tagPosition: "head",
          textContent: () => {
            return JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Выбираем фулфилмент-оператора и хотим экскурсию по вашему складу – это возможно?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Конечно возможно, мы регулярно организовываем экскурсию на склад Колибри93 в Краснодаре. Закажите обратный звонок или свяжитесь с нами сейчас, чтобы записаться на одну из них и узнать о нас больше."
                }
              }, {
                "@type": "Question",
                "name": "Как мне получить подробный прайс по нужным мне услугам?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Все очень просто: получите прайс заполнив заявку в соответствующем блоке на сайте, если там не найдется нужного набора услуг - свяжитесь с нами любым удобным способом на сайте и мы составим комфортный прайс конкретно под ваш запрос."
                }
              }, {
                "@type": "Question",
                "name": "У меня есть упаковка, но нет товарной этикетки, вы можете помочь?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Да, для своих клиентов мы не только предоставляем сам упаковочный материал, но и разрабатываем макет товарной этикетки соблюдая все технические требования и правила. Больше об услугах вы можете узнать скачав прайс или связавшись с нами."
                }
              }, {
                "@type": "Question",
                "name": "Какие категории товара запрещены к отправке?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Мы НЕ размещаем на складах: товары требующие соблюдения температурных режимов, крупногабаритные, легковоспламеняющиеся, взрывчатые вещества и предметы, промышленную химию, товары, которые нельзя продавать в онлайне."
                }
              }, {
                "@type": "Question",
                "name": "Как мне получить подробный прайс по нужным мне услугам?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Все очень просто: получите прайс заполнив заявку в соответствующем блоке на сайте, если там не найдется нужного набора услуг - свяжитесь с нами любым удобным способом на сайте и мы составим комфортный прайс конкретно под ваш запрос."
                }
              }, {
                "@type": "Question",
                "name": "Как заключить с вами договор? Какие документы вы предоставляете?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Процесс стандартный, длится от 7 до 10 дней. Закрывающие документы, включая акт сверки, предоставляются ежемесячно. Все финансовые расчёты с потребителем проводятся строго в соответствии с ФЗ-54"
                }
              }]
            })
          }
        },
      ]
    }
  },
  {
    name: 'not-found',
    path: '/:catchAll(.*)',
    component: async () => import('@/views/NotFound.vue'),
    meta: {
      name: '404 - страница не найдена'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
})

export default router;