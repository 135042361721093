import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router/router";
import { createHead } from "@vueuse/head";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

createApp(App)
  .use(createHead())
  .component('VueDatePicker', VueDatePicker)
  .use(router)
  .mount('#app')
